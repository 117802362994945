var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "monitor" },
    [
      _vm.mapPageParams.showHeader || _vm.mapPageParams.showMapTrackDetailButton
        ? _c(
            "div",
            { staticClass: "monitor-header" },
            [
              _c("map-tool", {
                attrs: { params: _vm.mapPageParams },
                on: { mapToolClick: _vm.handleMapToolClick },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "monitor-center",
          class: [
            _vm.mapPageParams.showHeader
              ? _vm.isFullscreen
                ? "with-header-fullscreen"
                : "with-header"
              : _vm.isFullscreen
              ? "no-header-fullscreen"
              : "no-header",
          ],
        },
        [
          _c("map-page", {
            ref: "mapRef",
            attrs: { params: _vm.mapPageParams },
            on: { mapPageClick: _vm.handleMapPageClick },
          }),
        ],
        1
      ),
      _vm._t("left"),
      _vm._t("right"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }